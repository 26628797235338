import { useEffect, useRef } from 'react';
import './App.css';
import NavBar from './Components/NavBar/NavBar.js';
import { ICON_NAMES } from './Styles/Icons/IconNames.tsx';
import ICONS from './Styles/Icons/Icons.tsx';
import Home from './Components/Home/Home.js';
import IPhoneShowcase from './Components/IPhoneShowcase/IPhoneShowcase.js';
import WebPanel from './Components/WebCard/WebPanel.js';
import LabelValueText from './Styles/Text/LabelValueText.tsx';
import { TextDirection } from './Styles/Text/Models/TextDirection.tsx';
import { Slide } from 'react-awesome-reveal';

function App() {
  const projectsRef = useRef(null)
  const swiftIosRef = useRef(null)
  const careerRef = useRef(null)
  const contactRef = useRef(null)

  let InstagramIcon = ICONS[ICON_NAMES.INSTAGRAM]
  let LinkedInIcon = ICONS[ICON_NAMES.LINKED_IN]
  let GithubIcon = ICONS[ICON_NAMES.GITHUB]
  let EmailIcon = ICONS[ICON_NAMES.EMAIL]

  useEffect(() => {
    const tileObserver = new IntersectionObserver(intersections => {
      intersections.forEach(({
        target,
        isIntersecting
      }) => {
        target.classList.toggle('animate', isIntersecting);
      });
    }, {
      threshold: 0
    });

    const webPanelObserver = new IntersectionObserver(intersections => {
      intersections.forEach(({
        target,
        isIntersecting
      }) => {
        target.classList.toggle('panel_animate', isIntersecting);
      });
    }, {
      threshold: 0
    });
    const webPanelTextObserver = new IntersectionObserver(intersections => {
      intersections.forEach(({
        target,
        isIntersecting
      }) => {
        target.classList.toggle('text_animate', isIntersecting);
      });
    }, {
      threshold: 0
    });
    
    document.querySelectorAll('.tile_panel').forEach(div => {
      tileObserver.observe(div);
    });
    document.querySelectorAll('.web_panel').forEach(div => {
      webPanelObserver.observe(div);
    });
    document.querySelectorAll('.web_panel_text').forEach(div => {
      webPanelTextObserver.observe(div);
    });
  })

  return (
    <div className="App font-titillium bg-black text-stone-950 sm:flex-row overflow-auto w-screen h-screen">
      <div className='relative flex flex-col'>
        <div>
          <NavBar projectsRef={projectsRef} swiftIosRef={swiftIosRef} careerRef={careerRef} contactRef={contactRef} />
          <Home projectsRef={projectsRef} />

          <div className='main_container text-white px-2 md:px-12'>
            
            <div ref={projectsRef} className='projects flex flex-col text-left text-3xl py-8'>
              <p className='font-bold text-6xl text-[#8D8D92] pb-8'>Projects</p>
              <div className='flex flex-row flex-wrap justify-between h-fit'>
                <WebPanel title={"Lauren Elizabeth Design"} description={"Freelance portfolio created for local artist"} tags={["Web", "React", "Node", "Tailwind"]} bg_from={"from-[#39CF89]"} bg_to={"to-[#88B09D]"} flex_grow={"md:basis-[59%] sm:basis-full"} cover='/assets/projects/laurenelizabeth/lauren-portfolio.png' />
                <WebPanel title={"Portfolio V1"} description={"First personal portfolio using the Delaunay algorithm for a generative background"} tags={["Web", "React", "Node", "Delaunay"]} bg_from={"from-[#CCD8D3]"} bg_to={"to-[#509C7C]"} flex_grow={"md:basis-[39%] sm:basis-full"} cover='/assets/projects/portfolio-v1/portfolio_v1.jpg' />
                <WebPanel title={"Motherload Clone"} description={"University project to create a 2D game creating our own game engine alongside"} tags={["C++", "SDL2", "Catch2", "SQL"]} bg_from={"from-[#802731]"} bg_to={"to-[#B988DE]"} flex_grow={"md:basis-[39%] sm:basis-full"} cover='/assets/projects/motherload/motherload.jpg' />
                <WebPanel title={"Sketch"} description={"Produced a web-based application to allow clinicians to annotate patient notes online and remove the need for paper"} tags={["Web", "JQuery", "ColdFusion", "Web Services"]} bg_from={"from-[#B58C8F]"} bg_to={"to-[#C28453]"} flex_grow={"md:basis-[59%] sm:basis-full"} cover='/assets/projects/uhcw_sketch/sketch.png' />
                <WebPanel title={"Screenshare"} description={"Create a Windows application that allows two devices to communicate over a local network and share user created data"} tags={["C#", "WPF", "Networking"]} bg_from={"from-[#B58C8F]"} bg_to={"to-[#C28453]"} flex_grow={"md:basis-[59%] sm:basis-full"} cover='/assets/projects/screenshare/screenshare.jpg' />
              </div>
            </div>
            
            <div ref={swiftIosRef} className='swift_ios projects flex flex-col text-left text-3xl h-screen py-11'>
              <p className='font-bold text-6xl text-[#8D8D92] pb-8'>Swift iOS</p>
              <div className='w-fit h-full flex justify-center items-center self-center overflow-hidden py-3'>
                <IPhoneShowcase />
              </div>
            </div>
            
            <div ref={careerRef} className='relative flex flex-col career text-left text-3xl h-screen py-8'>
              <p className='font-bold text-6xl text-[#8D8D92] pb-8'>Experience</p>
              
              <div className='flex h-full max-w-full self-center gap-3'>
                <div className='flex flex-col basis-0 flex-grow items-end h-full justify-around text-sm'>
                  <Slide direction='left' fraction={0.9} delay={300} triggerOnce>
                    <LabelValueText direction={TextDirection.right} 
                      title={"Jaguar Land Rover"}
                      role={"iOS Developer"}
                      date={"2022 - Present"}/>
                  </Slide>
                  <Slide direction='left' fraction={0.9} delay={300} triggerOnce>
                    <LabelValueText direction={TextDirection.right} 
                      title={"Coventry University"}
                      role={"BSc Computer Science"}
                      date={"2018 - 2022"}/>
                  </Slide>
                </div>
                <div className='self-center h-2/3 w-1 bg-lime-300'></div>
                <div className='flex flex-col basis-0 flex-grow items-start justify-around text-sm'>
                  <Slide direction='right' fraction={0.9} delay={300} triggerOnce>
                    <LabelValueText direction={TextDirection.left} 
                      title={"University Hospitals Coventry & Warwickshire"}
                      role={"Web Developer"}
                      date={"2020 - 2021"}/>
                  </Slide>
                </div>
              </div>

            </div>
          </div>

          <footer ref={contactRef} className='contact bg-lime-200 flex flex-col text-left text-3xl h-fit px-9 sm:px-24 py-8 gap-10'>
              <p className='font-bold text-6xl'>GET IN TOUCH</p>
              <div className='flex flex-col text-bold text-lg text-[#8D8D92]'>
                <span>I'd love to hear from you</span>
                <span>Reach out on any of my socials to have a chat :)</span>
              </div>
              <div className='self-center rounded-lg w-full h-[1px] bg-black'></div>
              <div className='flex flex-row gap-3'>
                  <InstagramIcon className="cursor-pointer" size="32" />
                <a href='https://www.linkedin.com/in/bradangliss/'>
                  <LinkedInIcon className="cursor-pointer" size="32" />
                </a>
                <a href='https://github.com/BradAngliss'>
                  <GithubIcon className="cursor-pointer" size="32" />
                </a>
                <a href="mailto:bradsjangliss@hotmail.co.uk?subject=Portfolio! : ">
                  <EmailIcon className="cursor-pointer" size="32" />
                </a>
              </div>
              <span className='text-sm text-bold'>© 2024 Brad Angliss</span>
          </footer>
        </div>
      </div>

    </div>
  );
}

export default App;