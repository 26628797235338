import { MdPhoneIphone } from "react-icons/md";
import { MdOutlineDesignServices } from "react-icons/md";
import { FaReact } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { HiChevronDown } from "react-icons/hi2";
import { IoHomeOutline } from "react-icons/io5";
import { PiBracketsCurlyBold } from "react-icons/pi";
import { CgWorkAlt } from "react-icons/cg";
import { FaInstagram } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";



let ICONS = {
    IPHONE: MdPhoneIphone,
    DESIGN: MdOutlineDesignServices,
    REACT: FaReact,
    GITHUB: FaGithub,
    LINKED_IN: FaLinkedin,
    CHEVRON_DOWN: HiChevronDown,
    HOME: IoHomeOutline,
    HOME_BRACKETS: PiBracketsCurlyBold,
    HOME_PROJECTS: CgWorkAlt,
    INSTAGRAM: FaInstagram,
    EMAIL: MdOutlineMailOutline
};

export default ICONS