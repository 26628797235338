import React from 'react'
import { TextDirection } from './Models/TextDirection.tsx'

interface LabelValueTextProps {
  direction: TextDirection;
  title: String;
  role: String;
  date: String;
}

function LabelValueText(props: LabelValueTextProps) {
  function Bar() {
      return <li className="h-6 w-1 mx-2 bg-white"></li>;
  }
  const isDirectionLeft = props.direction === TextDirection.left

  return (
    <div className='flex items-center gap-3'>
      {isDirectionLeft && 
        Bar()
      }
      <div className='max-w-48'>
        <p className='text-lime-300'>{props.role}</p>
        <p className='text-white'>{props.title}</p>
        <p className='text-[#8D8D92]'>{props.date}</p>
      </div>
      {!isDirectionLeft &&
        Bar()
      }
    </div>
  )
}



export default LabelValueText