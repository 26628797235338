import React from 'react'
import ICONS from '../../Styles/Icons/Icons.tsx';
import { ICON_NAMES } from '../../Styles/Icons/IconNames.tsx';
import SlideText from '../../Styles/SlideText/SlideText';
import './Home.css'

function Home({ projectsRef}) {
    let GithubIcon = ICONS[ICON_NAMES.GITHUB]
    let LinkedInIcon = ICONS[ICON_NAMES.LINKED_IN]
    let ChevronDown = ICONS[ICON_NAMES.CHEVRON_DOWN]

    const executeScroll = (ref) => { ref.current.scrollIntoView({
        behavior: 'smooth',
        block: "nearest",
        inline: "start"
      }) 
    }

    return (
        <div className='landing w-full h-screen bg-lime-200 flex flex-col relative overflow-hidden z-10'>
            <div className='flex flex-1 flex-col justify-center gap-8'>
                <div className="overlay"></div>
                <p className='font-semibold text-5xl sm:text-6xl'>BRAD ANGLISS</p>
                <SlideText />
                <span className='flex self-center gap-11'>
                    <a href='https://github.com/BradAngliss' >
                        <GithubIcon size={48} />
                    </a>
                    <a href='https://www.linkedin.com/in/bradangliss/' >
                        <LinkedInIcon size={48} />
                    </a>
                </span>
            </div>
            <div className='mb-8 self-center z-10 cursor-pointer' onClick={() => executeScroll(projectsRef)}>
                <ChevronDown size={20} />
            </div>
        </div>
    )
}

export default Home