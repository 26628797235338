import React from 'react'
import './NavBar.css'

function NavBar({ projectsRef, swiftIosRef, careerRef, contactRef }) {
  const executeScroll = (ref) => { ref.current.scrollIntoView({
      behavior: 'smooth',
      block: "nearest",
      inline: "start"
    }) 
  }

  return (
    <nav className='flex flex-row text-xs sm:text-sm font-medium py-2 select-none cursor-pointer justify-evenly w-full sm:w-2/3 right-0 absolute border-b-[1px] border-black z-50'>
        <div onClick={() => executeScroll(projectsRef)}>
          <p>Projects</p>
        </div>
        <div onClick={() => executeScroll(swiftIosRef)}>
          <p>Swift iOS</p>
        </div>
        <div onClick={() => executeScroll(careerRef)}>
          <p>Career</p>
        </div>
        <div onClick={() => executeScroll(contactRef)}>
          <p>Get in Touch</p>
        </div>
    </nav>
  )
}

export default NavBar