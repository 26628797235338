import React from 'react'

function InstagramPost({imgPath}) {
  return (
    <div className='text-black text-left rounded-md bg-[#F5F5F5] overflow-hidden'>
        <div className='text-sm title_banner w-full h-14 py-3 px-4 flex flex-row align-middle items-center gap-3 font-semibold'>
            <img className=' max-h-full max-w-full rounded-[40px]' src='/assets/instagram/swift_insta.jpeg' alt="bradangliss profile icon"></img>
            <p>bradangliss</p>
        </div>


        <video autoPlay loop muted webkit-playsinline="true" playsInline>
            <source src={imgPath} type="video/mp4" />
        </video>

        <div className='flex flex-col w-full px-3 pb-2'>
            <div className='footer_banner py-3 flex flex-row justify-between'>
                <div className='flex flex-row h-4 sm:h-6 gap-4'>
                    <img className=' max-h-full max-w-full' src="/assets/instagram/icons/like.png" alt="like icon"/>
                    <img className=' max-h-full max-w-full' src="/assets/instagram/icons/comment.png" alt="comment icon"/>
                    <img className=' max-h-full max-w-full' src="/assets/instagram/icons/share.png" alt="share icon"/>
                </div>
                <div className='h-4 sm:h-6'>
                    <img className=' max-h-full max-w-full' src="/assets/instagram/icons/bookmark.png" alt="bookmark icon"/>
                </div>
            </div>

            <div className='flex flex-row h-3 mb-3 text-sm items-center gap-1 text-nowrap'>
                <img className='max-h-full max-w-full rounded-[20px]' src='/assets/instagram/swift_insta.jpeg' alt="bradangliss profile icon"></img>
                <p className='text-xs sm:text-sm'>Liked by bradangliss and others</p>
            </div>

            <div className='text-xs sm:text-sm text-gray-500'>
                <p>April 9</p>
            </div>
        </div>
    </div>
  )
}

export default InstagramPost