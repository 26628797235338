import React from 'react'
import InstagramPost from '../InstagramPost/InstagramPost'
import './IPhoneShowcase.css'

import Reveal from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { Fade } from "react-awesome-reveal";


function IPhoneShowcase() {
  const customAnimation = keyframes`
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }

    80% {
      opacity: 1;
    }

    100% {
      visibility: hidden;
      opacity: 0;
    }
  `;

  return (
    <div className='flex h-3/4 sm:h-5/6'>
      <Fade className='h-full w-max' effect='fadeIn' fraction={0.3} duration={2000} triggerOnce >
          <div className='relative w-max sm:w-fit h-full'>
              <div className='bg-[#F5F5F5] rounded-[30px] w-[96%] left-[2%] h-[96%] top-[2%] absolute'>
                <Reveal className='absolute z-20 w-full h-full' keyframes={customAnimation} duration={3000} delay={1000} triggerOnce>
                  <div className='flex justify-center items-center bg-slate-700 w-full h-full rounded-[30px] opacity-80'>
                    <img className='w-12 invert' src='/assets/instagram/overlay/hand-scroll.svg' alt='Scroll Here' />
                  </div>
                </Reveal>
              </div>

              <div className='insta_feed w-[90%] left-[5%] h-[85%] mt-10 sm:mt-14 overflow-y-scroll absolute z-10'>
                  <InstagramPost imgPath={"/assets/instagram/spinners/blocks.mov"} />
                  <InstagramPost imgPath={"/assets/instagram/image_slider/slider.mov"} />
                  <InstagramPost imgPath={"/assets/instagram/pixel_quiz/pixel_main.mov"} />
              </div>
              <img className='relative z-0 h-full' src='/assets/instagram/iphone.png' alt="iPhone Showcase" draggable={"false"}/>
          </div>
      </Fade>
    </div>
  )
}

export default IPhoneShowcase