import React, { useRef } from 'react'
import './SlideText.css'

function SlideText() {
    return (
        <div className='flex w-full relative leading-[50px] '>
            <p className='display-text absolute top-0 left-0 right-0 z-10'></p>
            <div className='w-[150px] ml-[50%] -translate-x-1/2 z-20'>
                <div className='slide-left w-[200px] h-[50px] bg-lime-200 z-20'></div>
            </div>
        </div>
    )
}

export default SlideText