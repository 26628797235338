import React from 'react'
import classNames from 'classnames';
import './WebPanel.css'
import { Zoom } from "react-awesome-reveal";
import Breadcrumb from '../../Styles/Breadcrumb/Breadcrumb.tsx';

function WebPanel({ title, description, tags, cover, flex_grow, bg_from, bg_to }) {

  const className = classNames(
    "justify-between",
    flex_grow
  );
  
  const bgGradientColour = classNames(
    "bg-gradient-to-br rounded-[4rem] md:rounded-[6rem] z-0 h-full p-10 md:p-12 lg:p-16",
    bg_from,
    bg_to
  )

  return (
    <div className={className}>
      <Zoom className='h-full' cascade triggerOnce delay={200} fraction={0.1} damping={2}>
        {/* Tile */}
        <div className='flex flex-col relative h-full'>
          <div className={bgGradientColour}>
            <img className='w-auto md:h-full object-cover rounded-[2rem] sm:rounded-[3rem] shadow-slate-800 shadow-2xl' src={cover} alt="project"/>
          </div>
          {/* Text */}
          <div className='text-white py-4'>
            <p className='text-xl'>{title}</p>
            <p className='text-sm text-gray-600'>{description}</p>
            <Breadcrumb breadcrumb={tags}/>
          </div>
        </div>
      </Zoom>
    </div>
  )
}

export default WebPanel