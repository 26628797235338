import React from 'react'
import './Breadcrumb.css'

function Breadcrumb({breadcrumb}) {
    return (
        <div className='text-xs flex py-2'>
            <div className='flex flex-wrap breadcrumb px-[8px]'>
            {breadcrumb.map((text, i) => { 
                return <div className='crumb-container pb-2' key={i}>
                    <div className='crumb text-center align-middle'>
                        <div>
                            {text}
                        </div>
                    </div>
                </div>
            })}
            </div>
        </div>
    )
}

export default Breadcrumb