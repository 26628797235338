export const ICON_NAMES = {
    IPHONE: "IPHONE",
    DESIGN: "DESIGN",
    REACT: "REACT",
    GITHUB: "GITHUB",
    LINKED_IN: "LINKED_IN",
    CHEVRON_DOWN: "CHEVRON_DOWN",
    HOME: "HOME",
    HOME_BRACKETS: "HOME_BRACKETS",
    HOME_PROJECTS: "HOME_PROJECTS",
    INSTAGRAM: "INSTAGRAM",
    EMAIL: "EMAIL"
}